import { Injectable } from '@angular/core';
import { AccountingSearch } from '@dougs/operations/dto';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  buildSearchHttpParams(search: AccountingSearch): Record<string, string | string[]> {
    let params: Record<string, string | string[]> = {};
    if (search.search) {
      params = {
        ...params,
        q: search.search,
      };
    }

    if (search.amount) {
      params = {
        ...params,
        'q-amount': search.amount,
      };
    }

    if (search.accountingLineAmount) {
      params = {
        ...params,
        'q-accountingLineAmount': search.accountingLineAmount,
      };
    }

    if (search.date) {
      params = {
        ...params,
        'q-date': search.date,
      };
    }

    if (search.customAmount) {
      params = {
        ...params,
        amount: search.customAmount,
      };
    }

    if (search.customDate) {
      params = {
        ...params,
        date: search.customDate,
      };
    }

    if (search.type) {
      params = {
        ...params,
        type: search.type,
      };
    }

    if ((search.isInbound && !search.isNotInbound) || (!search.isInbound && search.isNotInbound)) {
      params = {
        ...params,
        'q-isInbound': search.isInbound ? 'true' : 'false',
      };
    }

    if (search.ecommerceCollection) {
      params = {
        ...params,
        'q-ecommerceCollection': search.ecommerceCollection ? 'true' : 'false',
      };
    }

    if (search.accountIds) {
      params = {
        ...params,
        'q-accountId': search.accountIds.map((accountId) => accountId.toString()),
      };
    }

    if (search.carIds) {
      params = {
        ...params,
        'q-carId': search.carIds.map((carId) => carId.toString()),
      };
    }

    if (search.accountNumberRanges) {
      params = {
        ...params,
        'q-accountingNumber[]': search.accountNumberRanges.map((accNbr) => JSON.stringify(accNbr)),
      };
    }

    if (search.accountingLineDate) {
      params = {
        ...params,
        'q-accountingLineDate': JSON.stringify(search.accountingLineDate),
      };
    }

    if (search.types) {
      params = {
        ...params,
        type: search.types.map((type) => type),
      };
    }

    if (search.accountingSurveyId !== undefined) {
      params = {
        ...params,
        accountingSurveyId: search.accountingSurveyId ? search.accountingSurveyId.toString() : 'null',
      };
    }

    if (search.isDraft) {
      params = {
        ...params,
        isDraft: search.isDraft.map((isDraft) => JSON.stringify(isDraft)),
      };
    }

    if (search.ignoreIsDraft) {
      params = {
        ...params,
        ignoreIsDraft: search.ignoreIsDraft ? 'true' : 'false',
      };
    }

    if (search.orders) {
      params = {
        ...params,
        orders: JSON.stringify(search.orders),
      };
    }

    return {
      ...this.buildExcludedAutogeneratedAndAll(search),
      ...params,
    };
  }

  buildSaveSearch(accountingSearch: AccountingSearch): Record<string, string | string[]> {
    let params: Record<string, string | string[]> = {};
    if (accountingSearch.search) {
      params = {
        ...params,
        q: accountingSearch.search,
      };
    }

    if (accountingSearch.amount) {
      params = {
        ...params,
        amount: accountingSearch.amount,
      };
    }

    if (accountingSearch.date) {
      params = {
        ...params,
        date: accountingSearch.date,
      };
    }

    if (
      (accountingSearch.isInbound && !accountingSearch.isNotInbound) ||
      (!accountingSearch.isInbound && accountingSearch.isNotInbound)
    ) {
      params = {
        ...params,
        isInbound: accountingSearch.isInbound ? 'true' : 'false',
      };
    }

    return {
      ...this.buildExcludedAutogeneratedAndAll(accountingSearch),
      ...params,
    };
  }

  buildExcludedAutogeneratedAndAll(accountingSearch: AccountingSearch): Record<string, string | string[]> {
    let params: Record<string, string | string[]> = {};
    if (accountingSearch.all) {
      params = {
        ...params,
        all: accountingSearch.all.toString(),
      };
    }

    if (accountingSearch.excludedOrDeleted) {
      params = {
        ...params,
        excludedOrDeleted: accountingSearch.excludedOrDeleted.toString(),
      };
    }

    if (accountingSearch.ecommerceCollection) {
      params = {
        ...params,
        ecommerceCollection: accountingSearch.ecommerceCollection.toString(),
      };
    }

    if (accountingSearch.autogenerated) {
      params = {
        ...params,
        autogenerated: accountingSearch.autogenerated.toString(),
      };
    }

    return params;
  }
}
